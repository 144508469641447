<template>
	<case-details @getCaseDetailsData="getCaseDetailsData" :caseStates="'review_detail'" @setInsuranceCompleted="setInsuranceCompleted">
		<template slot="pageBtn">
			<div class="action-btn">
				<comments-popover />
				<a-popover v-model="recordPopoverShow" trigger="click" placement="topLeft">
					<a-row slot="title" class="comments-title">
						<a-col :span="16"> {{ $t('table.loanReview.record') }} </a-col>
						<a-col :span="8" style="text-align: right">
							<a-icon @click="recordPopoverShow = false" type="close" />
						</a-col>
					</a-row>
					<div slot="content" class="record-content">
						<record :loanPreCreditResponse="loanPreCreditResponse" :checkRecords="checkRecords" :riskBasedFinancing="riskBasedFinancing"></record>
					</div>
					<a-button><img class="icon-btn" src="@/assets/Record.png" />{{ $t('table.loanReview.record') }} </a-button>
				</a-popover>
				<loan-brief v-if="loanBriefListData" :loanBriefListData="loanBriefListData" />
				<submit-review
					v-if="processStatus"
					:processStatus="processStatus"
					:loanPreCreditResponse="loanPreCreditResponse"
					:checkRecords="checkRecords"
					:riskBasedFinancing="riskBasedFinancing"
					:insuranceCompleted="insuranceCompleted"
				/>
			</div>
		</template>
	</case-details>
</template>
<script>
import CommentsPopover from '@/views/loanReview/components/CommentsPopover'
import LoanBrief from '@/views/loanReview/components/LoanBrief'
import SubmitReview from '@/views/loanReview/components/SubmitReview'
import Record from '@/views/loanReview/components/Record'
import CaseDetails from '@/views/businessComponents/CaseDetails'
import { apiAmountCalculate } from '@/api/check'
export default {
	data() {
		return {
			checkRecords: null,
			processStatus: null,
			loanPreCreditResponse: null,
			riskBasedFinancing: null,
			loanBriefListData: null,
			recordPopoverShow: false,
			insuranceCompleted: false
		}
	},
	components: {
		CommentsPopover,
		LoanBrief,
		SubmitReview,
		Record,
		CaseDetails
	},
	methods: {
		getCaseDetailsData(val) {
			this.loanBriefListData = {
				basicFullName: val.personalInfo.basicFullName,
				mobiles: val.personalInfo.mobiles,
				salesmanName: val.salesmanName,
				incomeSource: val.personalInfo.incomeSource,
				loanUse: val.personalInfo.loanUse,
				loanCarInfos: val.loanCarInfos,
				applyAmount: val.loanApplyInfo.applyAmount
			}
			this.checkRecords = val.checkRecords
			this.checkRecords.map((item, index) => {
				if (item.recommendAmountType && item.recommendAmount) {
					apiAmountCalculate({
						loanId: this.$route.query.id,
						type: item.recommendAmountType,
						amount: item.recommendAmount
					}).then((res) => {
						this.checkRecords[index].calAmount = res
					})
				}
			})
			this.loanPreCreditResponse = val.loanPreCreditResponse
			this.riskBasedFinancing = val.riskBasedFinancing
			this.processStatus = val.processStatus
			if (val.loanInfo.hasInsurance !== null) {
				this.insuranceCompleted = true
			}
		},
		setInsuranceCompleted(val) {
			this.insuranceCompleted = val
		}
	}
}
</script>
